import React from "react";
import { Box, Typography } from "@mui/material";
import { CustomButton } from "./CustomButton";
import moment from "moment";
const nextArrow = require("../../assets/images/chevron-up-orange.svg").default;

export default function YourSchedule({
  setActiveSteps,
  appointmentOptions,
  IsError,
  error,
  handleSelectedItems,
  setIsYourSchedule,
  locationBar
}) {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Typography>My Schedule</Typography>
        <CustomButton
          className="my-schedule-viewAllBtn"
          title={"View All"}
          color="#fff"
          disabled={!appointmentOptions?.length}
          backgroundColor="#32B2AC"
          onClick={() => setActiveSteps(10)}
        />
      </Box>
      {appointmentOptions ? (
        appointmentOptions?.slice(0, 2).map((item, index) => {
          const formattedDate = item?.fromDate?.replace(/-/g, '/')
          return (
            <Box className="class-section-box" key={index}>
              <Box className="class-section">
                <Typography>{item.serviceName}</Typography>
                {item?.fromDate &&
                  <Typography display={"flex"} alignItems={"center"}>
                    <span>
                      {item?.fromDate?.includes('-') ? moment(formattedDate)?.format("MMMM D, YYYY") : moment(item?.fromDate)?.format("MMMM D, YYYY")}
                    </span>
                    <span>{item?.clientDetails[0]?.waitingList && locationBar ?
                      <Typography color={"red !important"} ml={1} fontSize={"12px !important"} fontWeight={"600 !important"}>On Waitlist</Typography>
                      : null
                    }</span>
                  </Typography>
                }
              </Box>
              <Box display={"flex"} alignItems={"center"}>
                {item?.clientDetails[0]?.waitingList && !locationBar ?
                  <Typography color={"red !important"} mr={2} fontSize={"14px !important"} fontWeight={"600 !important"}>On Waitlist</Typography>
                  : null
                }
                <CustomButton
                  className="arrow-btn"
                  color="#E35205"
                  icon={nextArrow}
                  backgroundColor="#fff"
                  onClick={() => {
                    handleSelectedItems(item);
                    setIsYourSchedule(true);
                  }}
                />
              </Box>
            </Box>
          )
        })
      ) : (
        <Typography color={"black"} textAlign={"center"}>
          No schedule exists at this time.
        </Typography>
      )}
      {IsError ? (
        <Typography color={"black"} variant="body2" className="">
          No schedule exists at this time.
        </Typography>
      ) : (
        ""
      )}
    </>
  );
}
