import React, { useEffect, useMemo, useState, useContext } from "react";

import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  InputLabel,
  Slide,
  Typography,
} from "@mui/material";
import {
  CustomButton,
  CustomDropdown,
  CustomLoader,
} from "../../Components/Common";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  convertDateFormatManually,
  getLocalData,
  setLocalData,
  timeDifferenceCalculates,
} from "../../Utils";
import { GetAllPets } from "../../Services/APIs";
import moment from "moment";
import { ExploreContext } from "../../App";
import RegisterPop from "../../Components/Common/RegisterPop";
import { getPetsTrophies } from "../../Services/APIs";
import {
  getAllServices,
  getClientProfileProgress,
  getClientPurchase,
  getFreeOrientation,
} from "../../Services/APIs/client";
import { updateAppointment } from "../../Services/APIs";
import { CheckClientDetail } from "../../Services/APIs/profile";
import { useNavigate } from "react-router-dom";
import { getValidatePetEligibility } from "../../Services/APIs/appointments";
import {
  CustomDialogue,
  SuccessMessage,
  CustomErrorMessage,
} from "../../Components/Modals";
import AdditionalDogPopup from "../../Components/Common/AdditionalDogPopup";
import { ApiServices } from "../../Services/ApiServices";

const downArrow = require("../../assets/images/dropdownArrow.svg").default;
const banner = require("../../assets/images/bannerBookService.png");

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function BookService({
  handleNext,
  selected,
  setActiveSteps,
  selectedOption,
  setSelectedOption,
  setBookingType,
  setAllScheduleRoute,
  isWaitlist,
  paramsFilter,
}) {
  let navigate = useNavigate();
  let showSuccessMsg = getLocalData("CompleteProfileProceed");
  const { isGuest } = useContext(ExploreContext);
  const [open, setOpen] = useState(false);
  const [serviceDetail, setServiceDetail] = useState(selected?.[0] || {});
  const [dogList, setDogList] = useState([]);
  const [attendence, setAttendence] = useState([]);
  const [isDogSelect, setIsDogSelect] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [loader, setLoader] = useState(false);
  const [trophys, setTrophys] = useState([]);
  const [trophyExist, setTrophyExist] = useState("");
  const [trophyPopup, setTrophyPopup] = useState(false);
  const [serviceData, setServiceData] = useState([]);
  const [prerequisites, setPrerequisites] = useState("");
  const [isOutdated, setIsOutdated] = useState(false);
  const [clientProgess, setClientProgess] = useState("");
  const [clientProgressPopup, setClientProgressPopup] = useState(false);
  const [firstOrientation, setFirstOrientation] = useState(false);
  const [clientDetails, setClientDetails] = useState("");
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isWaitlistClient, setIsWaitlistClient] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  let clientId = getLocalData("clientId");
  let encodedClientId = encodeURIComponent(clientId);
  let clientName = getLocalData("clientName");

  useEffect(() => {
    if (
      paramsFilter !== null &&
      paramsFilter !== "" &&
      paramsFilter === "Orientation"
    ) {
      if (selected && selected[0]?.categoryName === paramsFilter) {
        setFirstOrientation(true);
      }
    }

    CheckClientDetail(clientId, encodedLocationId).then((response) => {
      if (
        response?.statusCode === 200 &&
        !response.error &&
        response?.data?.Items
      ) {
        setClientDetails(response.data.Items[0]);
      }
    });
  }, [paramsFilter]);
  useEffect(() => {
    if (firstOrientation) {
      setClientProgess(1);
    }
  }, [clientProgess, firstOrientation]);
  const [intervalCount, setIntervalCount] = useState(0);
  const [processing, setProcessing] = useState(false);
  const [tempData, setTempData] = useState({
    orderId: "",
    clientId: "",
  });
  useEffect(() => {
    if (processing && intervalCount < 15) {
      const interval = setInterval(() => {
        setIntervalCount(intervalCount + 1);
        getDetail();
      }, 4000);

      return () => {
        clearInterval(interval);
      };
    }

    if (intervalCount > 14) {
      setProcessing(false);
      setErrorMessage("Free orientation is not available");
    }

    // If processing is false, return a no-op function
    return () => {};
  }, [processing, intervalCount]);

  const getDetail = () => {
    const clientId = encodeURIComponent(tempData?.clientId);
    const orderId = encodeURIComponent(tempData?.orderId);
    getClientPurchase(clientId, orderId).then((res) => {
      try {
        if (res?.data?.Items?.[0]?.sortKey) {
          setProcessing(false);
          const purchseIdValue = res?.data?.Items?.[0]?.sortKey;
          setAppointment(purchseIdValue);
          setLoader(false);
        }
      } catch (error) {
        console.error(error);
        setLoader(false);
      }
    });
  };

  const getFreeClass = () => {
    setLoader(true);
    getFreeOrientation(encodedClientId, encodedLocationId, clientName).then(
      (response) => {
        try {
          if (response?.statusCode === 200 && response?.data?.orderId) {
            const data = {
              orderId: response?.data?.orderId,
              clientId: response?.data?.clientId,
            };
            setProcessing(true);
            setTempData(data);
          } else if (
            response?.statusCode === 200 &&
            !response?.data?.isPurchase
          ) {
            setLoader(false);
            setShowErrorMessage(true);
            setErrorMessage("Free orientation is not available");
          } else {
            setLoader(false);
            setShowErrorMessage(true);
            setErrorMessage("Free orientation is not available");
          }
        } catch (error) {
          console.log(error);
          setLoader(false);
        }
      }
    );
  };

  useEffect(() => {
    let totalWaitlistEntollments = selected[0]?.clientDetails?.filter(
      (value, index) => {
        return value?.waitingList === true;
      }
    );
    let BookedEnrollments = selected[0]?.clientDetails?.filter(
      (value, index) => {
        return (
          value?.status === "Booked" ||
          value?.status === "booked" ||
          value?.status === "checkIn" ||
          value?.status === "arrivedLate"
        );
      }
    );
    let waiting =
      BookedEnrollments?.length === selected[0]?.maximumCapacity ||
      (totalWaitlistEntollments?.length &&
        totalWaitlistEntollments?.length <
          selected[0]?.maximumWaitngListCapacity);
    setIsWaitlistClient(waiting ? waiting : false);
  }, [firstOrientation]);

  useEffect(() => {
    if (selected?.[0]) {
      // localStorage.removeItem('redirectionRoute')
      setServiceDetail(selected?.[0]);
    }

    if (selected?.[0]?.toDate) {
      const currentDate = new Date();
      const lastDate = new Date(selected?.[0]?.toDate);
      if (lastDate < currentDate) {
        setIsOutdated(true);
      } else if (lastDate?.toDateString() === currentDate?.toDateString()) {
        if (lastDate.getTime() < currentDate.getTime()) {
          setIsOutdated(true);
        } else {
          setIsOutdated(false);
        }
      } else {
        setIsOutdated(false);
      }
    }
  }, [selected]);

  useEffect(() => {
    getClientProfileProgressDetails();
  }, []);

  // getclient-progress
  const getClientProfileProgressDetails = () => {
    getClientProfileProgress().then((response) => {
      if (response?.statusCode === 200) {
        const data = response?.data;
        setClientProgess(Number(data?.clientProfileProgress));
        // setClientProgess(Number(0.2));
      }
    });
  };

  const handleProfileComplete = () => {
    setLocalData("CompleteProfileProceed", true);
    navigate("/profile", { state: { redirect: "edit" } });
  };

  const handlePetEligibilityCheck = async () => {
    const requestObj = {
      locationId: serviceDetail?.locationId,
      fromDate: convertDateFormatManually(serviceDetail?.fromDate),
      toDate: convertDateFormatManually(serviceDetail?.toDate),
      clientChildId: selectedOption?.dog?.value?.sortKey,
      clientParentId: selectedOption?.dog?.value?.partitionKey,
    };
    const response = await getValidatePetEligibility(requestObj);
    if (response?.statusCode === 200 && response?.data?.isExist) {
      setIsDogSelect("Selected pet is already enrolled in this time slot.");
    }
  };

  let encodedLocationId = encodeURIComponent(getLocalData("locationId"));

  const handleDropdownChange = (name, value) => {
    // getPetTrophy(value);
    setSelectedOption({ ...selectedOption, [name]: value });
    setIsDogSelect("");
  };
  let payload = useMemo(
    () => ({
      locationId: getLocalData("locationId"),
      occurrenceId: selected?.sortKey,
    }),
    [selected?.sortKey]
  );

  useEffect(() => {
    setServiceDetail(selected?.[0]);
    setAllScheduleRoute(false);
    GetAllPets().then(async (response) => {
      if (response) {
        const data = await response?.data?.Items?.map((item) => ({
          label:
            capitalizeWords(item.firstName) ||
            "" + capitalizeWords(item.lastName) ||
            "",
          value: item,
        }));
        setDogList(data);
      }
    });
    let tempAttendence = selected[0]?.clientDetails?.filter(
      (child, index) => child?.status === "booked" || child?.status === "Booked"
    );
    setAttendence(tempAttendence);
  }, [selected, payload]);

  useEffect(() => {
    if (dogList?.length > 0) {
      const petExists = attendence?.some((attendanceItem) => {
        return (
          // selectedOption?.dog?.value?.sortKey === attendanceItem?.clientChildId
          selectedOption?.dog?.value?.sortKey ===
            attendanceItem?.clientChildId &&
          (attendanceItem?.status === "Booked" ||
            attendanceItem?.status === "booked")
        );
      });

      if (petExists) {
        setIsDogSelect(
          "You have already enrolled a class for this pet. Please select another pet."
        );
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    }
  }, [selectedOption.dog, attendence, dogList]);

  useEffect(() => {
    if (selectedOption?.dog?.value?.sortKey) {
      handlePetEligibilityCheck();
    }
  }, [selectedOption?.dog?.value?.sortKey]);

  function capitalizeWords(str) {
    const words = str?.split(" ");
    const capitalizedWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    return capitalizedWords.join(" ");
  }

  const setAppointment = (purchaseId) => {
    let locationId = getLocalData("locationId");
    let paretlastName = clientDetails?.lastName;
    let childLastName = selectedOption.dog.value.lastName;
    setLoader(true);
    let AppointmentId = selected[0].appointmentId;
    let clientDetail = {
      payLater: false,
      bookedBy: clientDetails.firstName,
      creditAvailable: 1,
      serviceId: selected[0].serviceId,
      serviceName: selected[0].serviceName,
      categoryId: selected[0].categoryId,
      categoryName: selected[0].categoryName,
      checkIn: "00:00",
      clientParentId: clientDetails?.sortKey,
      clientChildId: selectedOption?.dog?.value?.sortKey,
      clientParentName: clientDetails?.firstName,
      // clientParentLastName: clientDetails?.lastName || " ",
      clientChildName: selectedOption?.dog?.value?.firstName,
      // clientChildLastName: selectedOption?.dog?.value?.lastName || " ",
      clientChildProfile: selectedOption?.dog?.value?.profileImage,
      clientChildBirthDate: selectedOption?.dog?.value?.birthDate,
      clientEmailId: clientDetails?.email,
      clientParentPhoneNumber: clientDetails.mobilePhone,
      purchaseId: [purchaseId],
      waitingList: isWaitlistClient,
      creditClientId: clientDetails?.sortKey
    };
    if (paretlastName) {
      clientDetails.clientParentLastName = paretlastName;
    }
    if (childLastName) {
      clientDetails.clientChildLastName = childLastName;
    }
    let payload = {
      updatedBy: clientId,
      locationId: locationId,
      fromDate: selected[0].fromDate,
      type: "occurrence",
      clientDetails: clientDetail,
    };
    try {
      updateAppointment(payload, AppointmentId).then((response) => {
        if (response.data.statusCode === 400 && response.statusCode === 200) {
          setShowErrorMessage(true);
          setErrorMessage(response.data.message);
          setLoader(false);
        } else if (response.statusCode === 200) {
          setLoader(false);
          setShowSuccessMessage(true);
          setSuccessMessage("Your Booking has been successfully confirmed.");
        } else {
          setLoader(false);
          setShowErrorMessage(true);
          setErrorMessage(response.message || "Something Went Wrong!");
        }
      });
    } catch (error) {
      setLoader(false);
      setShowErrorMessage(true);
      setErrorMessage(error.message || "Something Went Wrong!");
    }
  };

  const handleCheckOut = () => {
    setLocalData("CompleteProfileProceed", false);
    if (Number(clientProgess) < 1) {
      setClientProgressPopup(true);
    } else {
      if (isGuest) {
        setOpen(true);
      } else if (selectedOption?.dog?.label) {
        setIsDogSelect("");
        setBookingType("Enrollment");
        handleNext();
      } else {
        setIsDogSelect("This field is required.");
      }
    }
  };

  // PREREQUISITE CODE STARTS --------
  useEffect(() => {
    let foundService = null;

    serviceData?.forEach((item) => {
      if (item.sortKey === serviceDetail.serviceId) {
        foundService = item;
      }
    });

    setPrerequisites(foundService?.prerequisites);
  }, [serviceData]);

  useEffect(() => {
    let trophyIdsInDataset = trophys?.map((item) => item.trophyId);

    let extractedIds = Array.isArray(prerequisites)
      ? prerequisites.map((item) => item.id)
      : [];

    const found = extractedIds?.some((item) =>
      trophyIdsInDataset.includes(item)
    );
    if (extractedIds && extractedIds?.length > 0 && found) {
      setTrophyExist("");
      setTrophyPopup(false);
    } else {
      if (selectedOption?.dog?.label && extractedIds?.length) {
        setTrophyPopup(true);
        setTrophyExist(
          "Your dog does not have the prerequisites to enroll into this service. Please call us for assistance!"
        );
        setIsDisabled(true);
      }
    }
  }, [trophys, prerequisites]);
  const GetUpdatedLocationDetails = async () => {
    let franchiseeId = encodeURIComponent(getLocalData("franchiseeId"));
    let locationId = encodeURIComponent(getLocalData("locationId"));
 
    try {
      const response = await ApiServices.get(
        `${process.env.REACT_APP_API_URL2}locations/getLocationById?franchiseeId=${franchiseeId}&locationId=${locationId}&status=1`
      );
      if (response?.statusCode === 200) {
        if(response?.data?.Item?.phone){
          window.location.href = `tel:${response?.data?.Item?.phone}`;
          setActiveSteps(0)
        }else{
          setShowErrorMessage(true)
          setTrophyPopup(false)
          setErrorMessage('Location numbere not found')
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleClick = (val) => {
    if (val === "cancel") {
      setTrophyPopup(false)
      // setActiveSteps(0)
    }else{
      GetUpdatedLocationDetails()
      setTimeout(()=>{
        setTrophyPopup(false)
      },1500)
    }
  };
  useEffect(() => {
    if (isDogSelect || trophyExist) {
      setIsDisabled(true);
    }
  }, [isDogSelect, trophyExist]);

  const getServicesBasedOnCategory = async () => {
    let categoryId = encodeURIComponent(serviceDetail?.categoryId);
    const response = await getAllServices(categoryId, encodedLocationId);

    if (!response?.error) {
      setServiceData(response.data?.Items);
    } else {
      console.error("Error fetching pet trophies:", response.data?.error);
    }
  };

  useEffect(() => {
    if (serviceDetail?.categoryId) {
      getServicesBasedOnCategory();
    }
  }, [serviceDetail?.categoryId]);

  // useEffect(() => {

  //   if (selectedOption?.dog?.value?.sortKey) {

  //   }
  // }, [selectedOption.dog, attendence, dogList]);

  const getPetTrophy = async (value) => {
    let petId = selectedOption?.dog?.value?.sortKey ?? "";
    const response = await getPetsTrophies(petId);
    if (!response?.error) {
      setTrophys(response?.data?.Items);
    } else {
      console.error("Error fetching pet trophies:", response.data?.error);
    }
  };
  useEffect(() => {
    if (selectedOption?.dog?.value?.sortKey) {
      getPetTrophy();
    }
  }, [selectedOption?.dog]);
  const handleNavigateToPetProfile = (pet) => {
    localStorage.setItem("prevScreen", "explore");
    navigate("/profile", {
      state: { isAttendieProfile: true, attendieData: pet },
    });
  };
  const closePopup = () => {
    setActiveSteps(0);
    navigate(`/explore`);
  };
  const closeErrorPopUp = () => {
    setActiveSteps(0);
    setShowErrorMessage(false);
    navigate(`/explore`);
  };
  const handleSave = (value) => {
    setOpenDialog(true);
    if (value === "no") {
      setOpenDialog(false);
    }
    if (value === "yes") {
      setOpenDialog(false);
      getFreeClass();
    }
  };
  return (
    <>
      <CustomLoader IsLoading={loader} />
      <AdditionalDogPopup
        open={openDialog}
        // title={"Congratulations!"}
        setOpen={setOpenDialog}
        message={"Are you sure you want to sign up for FREE Orientation? "}
        handleProceed={handleSave}
      />

      <Box className="confirm-booking-main">
        {showSucessMessage && (
          <SuccessMessage
            message={sucessMessage}
            setShowMessage={setSuccessMessage}
            closePopup={closePopup}
          />
        )}
        {showErrorMessage && (
          <CustomErrorMessage
            message={errorMessage}
            setShowMessage={setShowErrorMessage}
            onclose={closeErrorPopUp}
          />
        )}
        <CustomDialogue
          type="progressCheckout"
          open={clientProgressPopup}
          className={"checkoutModal"}
          handleClose={() => setClientProgressPopup(false)}
          fullWidth
          handleNext={() => {
            setClientProgressPopup(false);
            navigate("/profile", { state: { redirect: "edit" } });
          }}
        />
        <RegisterPop
          open={open}
          setOpen={setOpen}
          messege="In order to add this service, you will have to create your profile, Do you want to create now ?"
        />
        <Box className="display-booking-wrap book-service-left">
          <Box className="img-section">
            <img src={banner} alt="section" className="service-img" />
            <Box className="overlay-content">
              <Box className="header">
                <Typography>{serviceDetail?.categoryName}</Typography>
                {/* <Typography>Next Session</Typography> */}
              </Box>
              <Box className="header" sx={{ gap: "20px" }}>
                <Box className="img-footer">
                  <Typography className="heading">
                    {serviceDetail?.serviceName}
                  </Typography>
                  <Box className="rating-section"></Box>
                </Box>

                <Box className="img-footer">
                  <Typography className="heading">
                    {moment(serviceDetail?.fromDate).format("MMMM D")}
                  </Typography>
                  {serviceDetail?.startTime && serviceDetail?.endTime && (
                    <Typography>
                      {serviceDetail?.startTime?.replace(/\s+/g, " ")} to{" "}
                      {serviceDetail?.endTime?.replace(/\s+/g, " ")} (
                      {timeDifferenceCalculates(
                        serviceDetail?.startTime?.replace(/\s+/g, " "),
                        serviceDetail?.endTime?.replace(/\s+/g, " ")
                      )}
                      )
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="booking-notes service-description">
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography className="heading">Description</Typography>
              {isWaitlist ? (
                <Typography
                  color={"red !important"}
                  fontFamily={"Univers LT Std"}
                  fontWeight={"700 !important"}
                >
                  On Waitlist
                </Typography>
              ) : null}
            </Box>
            <Typography className="description">
              {serviceDetail?.description}
            </Typography>
          </Box>
        </Box>
        <Box className="booking-notes-wrap book-term">
          <Box className="booking-notes service-wrap">
            <Box className="first-section">
              <Avatar
                src={
                  serviceDetail?.trainerProfileImage
                    ? serviceDetail?.trainerProfileImage
                    : "https://s3-alpha-sig.figma.com/img/f53e/1d24/fbad79be67f30faaa84b663526fc7319?Expires=1691971200&Signature=nHza3YVjuzDLlFNLw8dhzKEENSJe6NpGGeXc29FxHSUazhzzBZBnBgeaB~NpFhjIUkfnhPwN5ji281JrGHY1XQfgzFEX4Q60iIV-cdmsi6KOW4dEpVkT8t5SNguksFFJtby9QqnZZSH-~UTrquYPjlyqL5PM7wGfDsf-eDcm9fkFFKo6Js-KAOI0qDkUHq2ScO93gO1kRjZ0fs5OniFysumuVICMbzEHh03xBFL26gum7l4aPuOJUSaeVWFfu5JQpPMillOv6B3ZtV5Vtqfj7O7K76btJsJ6ZjksvnStZavnHBB9LEIc8AIITzvRPy-XfUstiPciilad4joeORindQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
                }
              />
              <Box className="display-attendee" sx={{ width: "100%" }}>
                <Box className="attendee-header">
                  <Typography>Trainer</Typography>
                </Box>
                <Box className="attendee-value">
                  <Typography width={{ xs: "100%", sm: "72%" }}>
                    {serviceDetail?.trainerName}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Divider />
            <Typography className="custom-heading">Attendees</Typography>
            <Box className="avatar-list" pb={3}>
              {attendence &&
                attendence.map((item, i) => (
                  <>
                    <Avatar
                      src={item.clientChildProfile}
                      sx={{ cursor: "pointer" }}
                      onClick={() => handleNavigateToPetProfile(item)}
                      key={i}
                    />
                    {/* dont remove this {item?.status === "Booked" || item?.status === "booked" ? <Avatar src={item.clientChildProfile} /> : null} */}
                  </>
                ))}
              <Typography>
                {attendence?.length > 7 ? "+" + (attendence?.length - 7) : ""}
              </Typography>
            </Box>
          </Box>

          <Box className="appointment-dropdown book-service">
            {!isGuest && <InputLabel>Select Your Pet</InputLabel>}
            {!isGuest && (
              <CustomDropdown
                placeHolder="Select Your Pet"
                className='pill-dropdown'
                value={selectedOption.dog}
                onChange={handleDropdownChange}
                options={dogList}
                icon={downArrow}
                fullWidth
                name={"dog"}
                error={isDogSelect}
                helperText={<span> {isDogSelect} </span>}
              />
            )}
          </Box>
          {!isGuest && !firstOrientation && (
            <>
              {clientProgess < 1 ? (
                <Typography
                  onClick={handleProfileComplete}
                  className="link-text"
                >
                  Click here to complete your profile prior to booking the
                  services.
                </Typography>
              ) : (
                <Typography className="">
                  {showSuccessMsg === "true"
                    ? "Your profile is completed successfully and you can book your services now."
                    : null}
                </Typography>
              )}
            </>
          )}

          <CustomButton
            className="book-btn"
            title={"Book"}
            color="#fff"
            disabled={isDisabled || isDogSelect || clientProgess < 1}
            backgroundColor="#32B2AC"
            iconJsx={<ChevronRightIcon />}
            fullWidth
            onClick={firstOrientation ? handleSave : handleCheckOut}
          />
        </Box>
      </Box>
      <Dialog
        open={trophyPopup}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className="signup_popup"
        sx={{ zIndex: 2000, padding: 1 }}
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="signup_location_popup_label">
                {trophyExist}
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 no-hover"
            onClick={() => {
              handleClick("callNow");
            }}
            sx={{
              color: "#003087",
              textTransform: "capitalize !important",
              fontWeight: "700 !important",
            }}
          >
            CALL NOW
          </Button>
          <Divider orientation="vertical" flexItem />
          <Button
            className="font-17 no-hover"
            sx={{
              color: "rgb(209, 58, 0)",
              textTransform: "capitalize !important",
              fontWeight: "700 !important",
            }}
            onClick={() => {
              handleClick("cancel");
            }}
          >
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>
    </>

    // <Typography>Hello{JSON.stringify(selected)}</Typography>
  );
}
